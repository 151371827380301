const Barrier = () => {



    return (<>
        <div className="card mt-3 mb-5">
            <a href="https://barriere.mtricci.it/" target="_blank">
                <img className="card-img-top" src="/assets/images/BarriereLogo.png" alt="barrier" />
            </a>
            <div className="card-body">
                <div className="yellow-box bg-yellow white-text mt-2">
                    <p className="white-text-size p-4">
                        Software per la valutazione dello stato di conservazione e funzionalità dei Dispositivi di Ritenuta Stradale basati sulle indicazioni riportate sul Quaderno Tecnico VI Marzo 2019 di ANAS
                    </p>
                </div>
                <div className="white-box yellow-text mt-2">
                    <p className="yellow-text-size">
                        Basato sui contenuti del Quaderno Tecnico VI Marzo 2019 di <span className="bold-text">Anas</span>
                    </p>
                    {/* <img src="/assets/images/certificate.png" className="cer-logo" alt="certificate" /> */}
                </div>
                {/* <div className="bg-yellow empty-space mt-4"></div> */}
            </div>
        </div>
    </>)
}

export default Barrier