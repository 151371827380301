const Project = () => {



    return (<>
        <div className="card mt-3 mb-5">
            <a href="https://ponti.mtricci.it/" target="_blank">
                <img className="card-img-top" src="/assets/images/PontiLogo.png" alt="building" />
            </a>
            <div className="card-body">
                <div className="yellow-box bg-yellow white-text mt-2">
                    <p className="white-text-size p-4">
                        Software per la valutazione dei difetti e della classe di attenzione dei ponti conforme ai contenuti delle linee guida del DM 204 del 01 luglio 2022
                    </p>
                </div>
                <div className="white-box yellow-text mt-2">
                    <p className="yellow-text-size">
                        Sviluppato e testato con il supporto dell'Università di <span className="bold-text">Roma Sapienza</span>
                    </p>
                    {/* <img src="/assets/images/certificate.png" className="cer-logo" alt="certificate" /> */}
                </div>
                {/* <div className="bg-yellow empty-space mt-4"></div> */}
            </div>
        </div>
    </>)
}

export default Project