const Building = () => {



    return (<>
        <div className="card mt-3 mb-5">
            <a href="https://edifici.mtricci.it/" target="_blank">
                <img className="card-img-top" src="/assets/images/EdificiLogo.png" alt="building" />
            </a>
            <div className="card-body">
                <div className="yellow-box bg-yellow white-text mt-2">
                    <p className="white-text-size p-4">
                        Software per la valutazione dei rischi idraulici, geologici e sismici basato sulle prescrizioni stabilite dall'EMS 98 e sul Metodo basato sui giudizi esperti sviluppato dal Gruppo Nazionale Difesa dei Terremoti
                    </p>
                </div>
                <div className="white-box yellow-text mt-2">
                    <p className="yellow-text-size">
                        Organismo di Ispezione di tipo C accreditato da <span className="bold-text">Accredia</span>
                    </p>
                    <img src="/assets/images/certificate.png" className="cer-logo" alt="certificate" />
                </div>
                {/* <div className="bg-yellow empty-space mt-4"></div> */}
            </div>
        </div>
    </>)
}

export default Building