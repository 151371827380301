import Building from "./Building"
import Project from "./Project";
import Barrier from "./Barrier";

const Home = () => {



    return (<>
        <div className="jumbotron bg-white">
            <div className="container">
                <img src="/assets/images/logo.png" width={300} alt="logo-image" />
                <p className="font-weight-bold font-italic text-center mt-2 main-text-size">
                    Software per la valutazione e la gestione dei rischi delle opere esistenti
                </p>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <Building />
                </div>
                <div className="col-md-4">
                    <Project />
                </div>
                <div className="col-md-4">
                    <Barrier />
                </div>
            </div>
        </div>
    </>)
}

export default Home;